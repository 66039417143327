import { Button, Modal, toast } from '@hyphen/hyphen-components';
import { useCallback, useRef } from 'react';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { InferType } from 'yup';

import { ApiError } from '../ApiError';
import { useCreateMemberMutation } from '../../services/member';
import { useReplaceTeamMembersMutation } from '../../services/teams';
import { ModalProps } from '../types/modal';
import { CreateMemberData, MemberSelect } from '../types/member';
import { SelectMember } from './SelectOrCreateMembers';

const addMemberSchema = yup.object().shape({
  members: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup
          .string()
          .email('Invalid email')
          .required(),
      })
    )
    .min(1, 'At least one member is required'),
});

type AddMemberSchema = InferType<typeof addMemberSchema>;


const errorMessages = {
  default: 'Failed to add member',
}


export const AddMemberModal = ({ isOpen, onClose, data }: ModalProps) => {
  const { id } = useParams<{ id: any }>();
  const modalRef = useRef<HTMLFormElement>(null);

  const [createMember, { error: createError }] = useCreateMemberMutation();
  const [replaceTeamMembers, { error: replaceError }] = useReplaceTeamMembersMutation();


  const handleInviteMember = useCallback(async (newMembers: MemberSelect[]) => {
    const responses = await Promise.all(newMembers.map(async (member) => await createMember({
      email: member.label,
      organizationId: data.organization.id
    }))) as CreateMemberData[];

    if (responses.length) {
      return responses.map(response => response.data?.id);
    }

    return [];
  }, [createMember, data.organization.id])

  const handleOnSubmit = useCallback(async (values: AddMemberSchema) => {
    let memberIds = values.members
      ?.filter((member: MemberSelect) => !member.__isNew__)
      .map((member: MemberSelect) => member.value) ?? [];

    const newMembers = values.members?.filter((member: MemberSelect) => member.__isNew__);
    const newMemberIds = newMembers?.length ? await handleInviteMember(newMembers) : [];

    if (newMemberIds.length) {
      memberIds = [...memberIds, ...newMemberIds];
    }

    const { error } = await replaceTeamMembers({
      orgId: data.organization.id,
      teamId: id,
      data: { memberIds }
    });

    if (!error) {
      toast.success('Member added', { duration: 5000 });
      onClose();
    }
  }, [data.organization.id, handleInviteMember, onClose, replaceTeamMembers, id]);

  return (
    <Modal isOpen={isOpen} onDismiss={onClose} maxWidth="9xl" ref={modalRef}>
      <Modal.Header id="addMemberModal" title="Add Member" onDismiss={onClose} />
      <Formik
        initialValues={{ members: [] }}
        validationSchema={addMemberSchema}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form noValidate>
            <Modal.Body padding="4xl 4xl 5xl" margin="0 0 4xl" gap="lg">
              <SelectMember
                label="Members"
                name="members"
                isMulti
                isCreatable
                error={errors.members}
                portalTarget={modalRef.current}
              />
              {createError || replaceError ? <ApiError error={createError || replaceError} customMessages={errorMessages} /> : null}
            </Modal.Body >

            <Modal.Footer>
              <Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" isLoading={isSubmitting}>
                Save
              </Button>
            </Modal.Footer>
          </Form >
        )}
      </Formik >
    </Modal >
  )
}
