import { api } from './api';
import { ListResponse, QueryParams } from './types';

export interface Env {
  id: string;
  size: string;
  countVariables: number;
  version: number;
  secretKeyId: number;
  published: string;
  projectEnvironment?: {
    id: string;
    alternateId: string;
    name: string;
  };
}

export interface EnvsParams {
  organizationId: string;
  appIds?: string[];
  projectIds?: string[];
}

export interface EnvVersionsParams {
  organizationId: string;
  appId: string;
  environmentId?: string;
  secretKeyId?: number;
}

export const envApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEnvs: builder.query<ListResponse<Env>, EnvsParams & Pick<QueryParams, 'pageSize' | 'pageNum'>>({
      query: ({ pageSize, pageNum, organizationId, appIds, projectIds }) => {
        const params = new URLSearchParams();
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (appIds) params.append('appIds', appIds.join(','));
        if (projectIds) params.append('projectIds', projectIds.join(','));
        return {
          url: `api/organizations/${organizationId}/dot-envs?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result) => (result ? [{ type: 'Env', id: 'ENVS-LIST' }] : []),
    }),
    getEnvVersions: builder.query<ListResponse<Env>, EnvVersionsParams & Pick<QueryParams, 'pageSize' | 'pageNum'>>({
      query: ({ pageSize, pageNum, organizationId, appId, environmentId, secretKeyId }) => {
        const params = new URLSearchParams();
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (environmentId) params.append('environmentId', environmentId);
        if (secretKeyId) params.append('secretKeyId', secretKeyId.toString());
        return {
          url: `api/organizations/${organizationId}/apps/${appId}/dot-env/versions?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result) => (result ? [{ type: 'Env', id: 'ENVS-LIST' }] : []),
    }),
  }),
});

export const { useGetEnvsQuery, useGetEnvVersionsQuery } = envApi;
