import { useCallback, useEffect, useState } from "react";
import { Member } from "../../types/teams";
import { FormikSelectInput } from "@hyphen/hyphen-components";
import { Field } from "formik";
import { useOrganization } from "../../providers/OrganizationProvider";
import { useLazyGetMembersQuery } from "../../services/member";
import { Organization } from "../../services/organization";

interface SelectMemberProps {
  isMulti?: boolean;
  isCreatable?: boolean;
  portalTarget?: HTMLFormElement | null;
  label?: string;
  name?: string;
  error?: string | string[];
}


export function createOptionsFromResults(members: Member[]) {
  return members?.map((member) => ({
    value: member.id,
    label: (member.nickname && member.nickname !== ' ') ? `${member.nickname} (${member.email})` : member.email,
  })) || [];
}

export function SelectMember({ isMulti = false, isCreatable = true, error, portalTarget, label = "Members", name = "members" }: SelectMemberProps) {
  const { organization = {} as Organization } = useOrganization();
  const [trigger] = useLazyGetMembersQuery();


  const loadOptions = useCallback(async (inputValue: string) => {
    if (!inputValue) return [];

    const filteredMembers = await trigger({ organizationId: organization.id, email: inputValue }).unwrap();
    return createOptionsFromResults(filteredMembers?.data);
  }, [organization.id, trigger]);

  const [defaultOptions, setDefaultOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    const fetchMembers = async () => {
      const membersResult = await trigger({ organizationId: organization.id }).unwrap();
      setDefaultOptions(createOptionsFromResults(membersResult?.data));
    };

    fetchMembers();
  }, [organization.id, trigger]);




  return (
    <Field
      label={label}
      name={name}
      id={name}
      options={loadOptions}
      component={FormikSelectInput}
      isMulti={isMulti}
      isCreatable={isCreatable}
      isAsync
      cacheOptions
      error={error}
      menuPortalTarget={portalTarget}
      defaultOptions={defaultOptions}
    />
  );
}