import { Badge, Box, Button, Heading, Spinner, useOpenClose } from '@hyphen/hyphen-components';
import { Link, Outlet, useParams } from 'react-router-dom';
import { useGetTeamQuery } from '../../services/teams';

import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { useOrganization } from '../../providers/OrganizationProvider';
import { TeamMembersList } from '../../components/teams/TeamMembersList';
import { Organization } from '../../services/organization';
import ClickToCopy from '../../components/common/ClickToCopy';
import DynamicTeamCriteria from '../../components/teams/DynamicTeamCriteria';
import { TeamDetails } from '../../types/teams';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { AddMemberModal } from '../../components/members/AddMemberModal';

export const TeamDetail = () => {
  const { organization = {} as Organization } = useOrganization();
  const { id } = useParams<{ id: any }>();

  const { isLoading, data: team = {} as TeamDetails } = useGetTeamQuery({ orgId: organization.id, id });

  const { isOpen: isAddMemberOpen, handleToggle: toggleAddMember, handleClose: closeAddMember } = useOpenClose();


  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ScrollableMainLayout>
      <Helmet title={`${isLoading ? 'loading' : team?.name} - Team`} />
      <Box gap="4xl">
        <Box
          width="100"
          direction={{ base: 'column', tablet: 'row' }}
          gap={{ base: '2xl', tablet: '5xl' }}
          alignItems={{ tablet: 'center' }}
        >
          <PageHeader
            flex="auto"
            eyebrow={<Link to={`/${organization?.id}/teams`}>Teams</Link>}
            title={
              <Box direction="row" gap="md" alignItems="center">
                {team?.name} {team?.type === 'dynamic' && <Badge message="dynamic" variant="purple" size="sm" />}
              </Box>
            }
            description={
              team.distributionList && <ClickToCopy text={team.distributionList.email} hideIcon fontSize="xs" />
            }
          />
          <Box direction="row" gap="lg">
            {team?.type === 'static' &&
              <Button onClick={toggleAddMember}>
                Add members
              </Button>
            }
            <Link
              to="edit"
              className="hyphen-components__variables__form-control Button-module_button__18Bed Button-module_size-md__BkuGu Button-module_secondary__j-3rj"
            >
              Settings
            </Link>
          </Box>
        </Box>
        {team.type === 'dynamic' ? <DynamicTeamCriteria criteria={team.criteria || []} /> : null}
        {team.channels?.length! > 0 && (
          <Box gap="2xl">
            <Heading as="h2" size="sm">
              Channels <span className="font-size-md font-color-secondary">({team.channels!.length})</span>
            </Heading>
            <div>
              {team.channels?.map((channel) => (
                <span key={channel.id}>
                  {channel.type} - {channel.id},{' '}
                </span>
              ))}
            </div>
          </Box>
        )}

        <TeamMembersList organizationId={organization?.id || ''} teamId={id} teamType={team?.type} />
      </Box>
      <Outlet />

      <AddMemberModal isOpen={isAddMemberOpen} onClose={closeAddMember} data={{ organization }} />

    </ScrollableMainLayout>
  );
};

export default TeamDetail;
