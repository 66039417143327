import { Box, Button, Heading, Spinner, Table, TextInput } from '@hyphen/hyphen-components';
import { useGetTeamMembersQuery } from '../../services/teams';
import EmptyList from '../common/EmptyList';
import { useState } from 'react';
import DeleteTeamMember from './DeleteTeamMember';

interface TeamMembersListProps {
  organizationId: string;
  teamId: string;
  teamType?: string;
}

export const TeamMembersList = ({ organizationId, teamId, teamType }: TeamMembersListProps) => {
  const { isLoading, data: members } = useGetTeamMembersQuery({
    orgId: organizationId,
    id: teamId,
  });

  const isStaticTeam = teamType === 'static';

  const [searchValue, setSearchValue] = useState('');

  const filteredMembers = members?.data.filter(
    (member) =>
      member.firstName?.toLowerCase().includes(searchValue.toLowerCase()) ||
      member.lastName?.toLowerCase().includes(searchValue.toLowerCase()) ||
      member.email.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const columnConfig = [
    {
      heading: 'Name',
      dataKey: 'name',
      render: (_cell: any, row: any) => (row.firstName || row.lastName) ? `${row.firstName} ${row.lastName}` : '',
    },
    {
      heading: 'email',
      dataKey: 'email',
    },
    {
      heading: '',

      render: (_cell: any, row: any) =>
        isStaticTeam && (
          <Box gap="sm" direction="row" justifyContent="flex-end">
            <DeleteTeamMember organizationId={organizationId} teamId={teamId} memberId={row.id} />
          </Box>
        ),
    },
  ];

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box gap="2xl">
      <Box display="block" childGap="sm">
        <Heading as="h2" size="sm">
          Members <span className="font-size-md font-color-secondary">({members?.data.length})</span>
        </Heading>
      </Box>
      <Box justifyContent="space-between" direction="row" gap="2xl">
        <Box width={{ base: '7xl', tablet: '8xl' }}>
          <TextInput
            id="searchMembersInput"
            placeholder="Search members"
            label="Search members"
            hideLabel
            onClear={() => setSearchValue('')}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
        {/* {isStaticTeam && (
          <Link
            to="add"
            className="hyphen-components__variables__form-control Button-module_button__18Bed Button-module_size-md__BkuGu Button-module_primary__st6yY"
          >
            Add Members
          </Link>
        )} */}
      </Box>

      {members?.data.length === 0 ? (
        <EmptyList
          title="This team doesn't have any members"
          iconName="users"
          description="Members of a team will inherit roles and permission assigned to the team"
        />
      ) : (
        <>
          {filteredMembers?.length === 0 ? (
            <EmptyList title="No members found" iconName="search">
              <Button variant="secondary" onClick={() => setSearchValue('')} size="sm">
                Clear filter
              </Button>
            </EmptyList>
          ) : (
            <Box borderColor="default" borderWidth="sm sm 0 sm" radius="md" overflow="hidden" fontSize="sm">
              <Table rowKey="id" columns={columnConfig} rows={filteredMembers || []} isLoading={isLoading} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
