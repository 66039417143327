import { Box, Button, FormikSelectInput, Modal, toast, useOpenClose } from "@hyphen/hyphen-components";
import { Project, Role, useGiveAccessMutation } from "../../services/projects";
import { useOrganizationAbilityContext } from "../auth/OrganizationAbilityProvider";
import { useCallback, useRef } from "react";
import * as yup from 'yup';
import { InferType } from "yup";
import { Formik, Field, Form } from "formik";
import { ApiError } from "../ApiError";
import { SelectMember } from "../members/SelectOrCreateMembers";
import { useCreateMemberMutation } from "../../services/member";

const giveMemberAccessSchema = yup.object().shape({
  member: yup.object().shape({
    label: yup.string(),
    value: yup.string().required('Please select a member'),
    __isNew__: yup.boolean()
  }).required('Please select a member'),
  roles: yup.array(yup.object().shape({
    label: yup.string(),
    value: yup.string().required()
  })).required('Roles are required'),
})

type GiveMemberAccessSchema = InferType<typeof giveMemberAccessSchema>;


export default function GiveAccess({ project }: { project: Project }) {
  const ability = useOrganizationAbilityContext();
  const canUpdateProject = ability.can('update', project);
  const [createMember, { error: createError }] = useCreateMemberMutation();
  const [giveAccess, { error, isLoading }] = useGiveAccessMutation();
  const modalRef = useRef<HTMLFormElement>(null);
  const { isOpen, handleToggle, handleClose } = useOpenClose();


  const errorMessages = {
    default: 'Failed to invite member',
  }

  const RoleOptions = [
    {
      label: "Project Viewer",
      value: Role.ProjectViewer
    },
    {
      label: "Project Collaborator",
      value: Role.ProjectCollaborator
    },
    {
      label: "Project Owner",
      value: Role.ProjectOwner
    },
  ]

  const handleOnSubmit = useCallback(async (values: GiveMemberAccessSchema) => {
    let memberId = values.member.value;

    if (values.member.__isNew__) {
      const createdMember = await createMember({
        email: values.member.value,
        organizationId: project.organization.id
      });
      if (createdMember.data && !createError)
        memberId = createdMember.data?.id
    }

    const { error } = await giveAccess({
      projectId: project.id,
      organizationId: project.organization.id,
      memberId: memberId,
      roles: (values.roles || []).map(r => r.value as Role)
    });
    if (!error) {
      toast.success('Access Given', { duration: 5000 });
      handleClose();
    }
  }, [createError, createMember, giveAccess, handleClose, project.id, project.organization.id])

  if (!canUpdateProject)
    return null;

  return (
    <Box alignItems="flex-start" gap="xl">
      <Button
        variant="primary"
        size="sm"
        iconPrefix="add"
        onClick={handleToggle}
      >
        Give Access
      </Button>
      <Modal ariaLabelledBy="inviteMemberModal" isOpen={isOpen} onDismiss={handleClose} maxWidth="9xl" ref={modalRef}>
        <Modal.Header id="inviteMemberModal" title="Give Access" onDismiss={handleClose} />
        <Formik
          initialValues={{ member: { value: '', label: '' }, roles: [] }}
          validationSchema={giveMemberAccessSchema}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting, errors }) => (
            <Form noValidate>
              <Modal.Body padding="4xl" gap="xl">
                <SelectMember
                  label="Member"
                  name="member"
                  error={errors.member?.value}
                  isMulti={false}
                  portalTarget={modalRef.current}
                />
                <Field
                  label="Roles"
                  name="roles"
                  id="roles"
                  options={RoleOptions}
                  component={FormikSelectInput}
                  error={errors.roles}
                  isMulti
                  isRequired
                  isDisabled={isLoading}
                  menuPortalTarget={modalRef.current}
                />
                {error ? <ApiError error={error} customMessages={errorMessages} /> : null}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} isDisabled={isSubmitting}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" isLoading={isSubmitting}>
                  Give Access
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Box>);
}