import { Box, Button, Pagination, Spinner, Table, toast } from "@hyphen/hyphen-components";
import { Project, useGetAccessQuery, useRevokeAccessMutation } from "../../services/projects";
import useQueryStringState from "../../hooks/useQueryStringState";
import { useCallback } from "react";
import { RoleDisplayName } from "../../utils/roles";
import { useOrganizationAbilityContext } from "../auth/OrganizationAbilityProvider";

export default function ProjectAccessList({ project }: { project: Project }) {

  const [qsState, setQsState] = useQueryStringState({ pageNum: 1, pageSize: 50 });
  const { data: access, isLoading } = useGetAccessQuery({
    projectId: project.id,
    organizationId: project.organization.id,
    pageNum: qsState.pageNum,
    pageSize: qsState.pageSize
  });

  const handlePageNumberChange = useCallback((pageNum: number) => {
    setQsState({ pageNum, pageSize: qsState.pageSize });
  }, [qsState.pageSize, setQsState]);

  const [revokeAccess, { isLoading: isRevoking }] = useRevokeAccessMutation();

  const revokeAccessHandler = useCallback(async (accessId: string) => {
    const { error } = await revokeAccess({
      organizationId: project.organization.id,
      accessId: accessId,
      projectId: project.id
    });
    if (error) {
      toast.error("unable to revoke Access", { duration: 5000 })
    } else {
      toast.success("access revoked", { duration: 2000 });
    }
  }, [project.id, project.organization.id, revokeAccess]);

  const ability = useOrganizationAbilityContext();
  const canUpdateProject = ability.can('update', project);

  const columnConfig = [
    {
      heading: 'Name',
      dataKey: 'assignment',
      render: (assignment: any) => assignment.name
    },
    {
      heading: "Roles",
      dataKey: "roles",
      render: (roles: any) => (roles || []).map(RoleDisplayName).join(', ')
    },
  ]

  if (canUpdateProject) {
    columnConfig.push({
      heading: "",
      dataKey: "id",
      render: (id: any) => <Button variant="danger" isLoading={isRevoking} iconPrefix="trash" size="sm" aria-label="revoke" title="revoke" onClick={() => revokeAccessHandler(id)} />
    })
  }


  return (
    <>
      <Box alignItems="flex-start" gap="xl">
        {isLoading ? <Box><Spinner /></Box> : (
          <Box width="100" gap="lg">
            <Table
              rowKey="id"
              isLoading={isLoading}
              columns={columnConfig}
              rows={access?.data || []}
            />
            {
              access?.total && access?.total > qsState.pageSize ? (
                <Pagination
                  activePage={qsState.pageNum}
                  itemsPerPage={qsState.pageSize}
                  onChange={handlePageNumberChange}
                  totalItemsCount={access?.total ?? 0}
                  numberOfPagesDisplayed={3}
                  isCompact
                  arePagesVisible
                />
              ) : null
            }
          </Box>
        )}
      </Box>
    </>);
}