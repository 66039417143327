import { Box, Drawer, Icon, Modal, Popover, Spinner, useBreakpoint, useOpenClose } from '@hyphen/hyphen-components';
import { Link, NavLink, Route, Routes, useLocation } from 'react-router-dom';

import Dashboard from './Organization/Dashboard';
import IntegrationDetails from './Organization/Integrations/IntegrationDetails';
import IntegrationsOverview from './Organization/Integrations/IntegrationsOverview';
import MainCard from '../components/MainCard';
import MainNavItem from '../components/MainNavItem';
import MobileHeader from '../components/MobileHeader';
import { OrganizationAvatar } from '../components/organization/OrganizationAvatar';
import Settings from './Organization/Settings';
import ShortLinkDashboard from './Organization/ShortLinkDashboard';
import ShortLinkDetail from './Organization/ShortLinkDetail';
import Teams from './Organization/Teams';
import { TeamDetail } from './Organization/TeamDetail';
import { useAuth } from '../components/auth/authProvider';
import { useOrganizationsList } from '../providers/OrganizationsListProvider';

import EnvDashboard from './Organization/EnvDashboard';
import CreateApp from './Organization/CreateApp';
import AppContainer from './Organization/AppContainer';
import { routes } from '..';
import { OrganizationProvider, useOrganization } from '../providers/OrganizationProvider';
import { IntegrationSetup } from './Organization/Integrations/IntegrationSetup';
import { CreateTeam } from './Organization/CreateTeam';
import CreateLink from './Organization/CreateLink';
import Events from './Organization/Events';
import GetStarted from '../components/GetStarted';
import { Organization, useGetOrganizationBillingAccountQuery } from '../services/organization';
import TeamSettings from './Organization/TeamSettings';
import {
  OrganizationAbilityProvider,
  useOrganizationAbilityContext,
} from '../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '../types/executionContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ComingSoon from '../components/ComingSoon';
import CreateProject from './Organization/Projects/CreateProject';
import ProjectContainer from './Organization/ProjectContainer';

export const OrganizationBase = () => {
  const { isLoading } = useOrganizationsList();

  const {
    isOpen: isMainNavOpen,
    handleClose: handleMainNavClose,
    handleToggle: handleMainNavToggle,
  } = useOpenClose();

  return (
    <>
      <OrganizationProvider>
        <OrganizationAbilityProvider>
          <Box
            direction={{ base: 'column', desktop: 'row' }}
            width="100"
            height="100"
            minHeight="100"
            margin="0"
            alignItems="stretch"
          >
            {isLoading && <Spinner size="xl" />}
            {!isLoading && (
              <>
                <Navigation isMainNavOpen={isMainNavOpen} handleMainNavClose={handleMainNavClose} />
                <Box
                  as="main"
                  style={{ flex: '1 1 auto' }}
                  width="100"
                  height="100"
                  direction="column"
                  padding={{ base: '0', desktop: 'xl xl xl 0' }}
                  overflow="hidden"
                >
                  <MobileHeader handleToggle={handleMainNavToggle} />
                  <MainContent />
                </Box>
              </>
            )}
          </Box>
        </OrganizationAbilityProvider>
      </OrganizationProvider>
    </>
  );
};

const Navigation = ({
  isMainNavOpen,
  handleMainNavClose,
}: {
  isMainNavOpen: boolean;
  handleMainNavClose: () => void;
}) => {
  const { organization = {} as Organization } = useOrganization();
  const { organizations = [] } = useOrganizationsList();
  const { isPhone, isTablet } = useBreakpoint();
  const ability = useOrganizationAbilityContext();
  const canManageOrganizationIntegrations = ability.can('manage', EntityNames.OrganizationIntegration);
  const canInviteMembersToOrganization = ability.can('create', EntityNames.Member);
  const canReadEvents = ability.can('read', EntityNames.Event);

  const { logout, user } = useAuth();

  const { isOpen, handleClose, handleToggle } = useOpenClose();

  const {
    isOpen: isOrgSwitcherOpen,
    handleClose: handleOrgSwitcherClose,
    handleToggle: handleToggleOrgSwitcher,
  } = useOpenClose();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();

  const isMobile = isPhone || isTablet;

  const handleMobileToggleOrgSwitcher = () => {
    if (isMobile) {
      openModal();
      handleMainNavClose();
      handleClose();
    } else {
      handleToggleOrgSwitcher();
    }
  };

  const handleClickOutside = () => {
    handleClose();
    handleOrgSwitcherClose();
  };

  const hasMultipleOrgs = organizations?.length > 1;

  const orgContent = (
    <Box>
      {hasMultipleOrgs && (
        <Box
          display="grid"
          gap={{ base: 'xs', desktop: '0' }}
          padding={{ base: '0 lg lg lg', desktop: 'md' }}
          overflow="auto"
          maxHeight="8xl"
          className="scroll-bar-thin"
        >
          {organizations?.map((org) => (
            <Link to={`/${org.id}`} key={org.id} className="navlink" onClick={closeModal}>
              <Box
                padding={{ base: 'lg', desktop: 'md' }}
                hover={{ background: 'secondary' }}
                radius={{ base: 'md', desktop: 'xs' }}
                direction="row"
                justifyContent="space-between"
                fontWeight="medium"
                borderColor="default"
              >
                <Box direction="row" alignItems="center" gap="sm">
                  <OrganizationAvatar name={org.name} logoUrl={org.logoUrl} />
                  {org.name}
                </Box>
                {org.id === organization?.id && <Icon name="check" size="sm" />}
              </Box>
            </Link>
          ))}
        </Box>
      )}
      <Box borderColor="default" borderWidth="sm 0 0 0" padding={{ base: 'lg', desktop: 'md' }}>
        <Link to={`/${routes.getStarted}`} className="navlink">
          <Box
            padding={{ base: 'lg', desktop: 'md' }}
            hover={{ background: 'secondary' }}
            radius={{ base: 'md', desktop: 'xs' }}
            fontWeight="medium"
            borderColor="default"
            direction="row"
            gap="sm"
          >
            <Icon name="add" size="sm" />
            <span>Create an organization</span>
          </Box>
        </Link>
      </Box>
    </Box>
  );

  const orgList = hasMultipleOrgs ? (
    <Popover
      content={orgContent}
      isOpen={isOrgSwitcherOpen}
      placement="right-start"
      offsetFromTarget={12}
      trapFocus
      contentContainerProps={{
        padding: '0',
        shadow: 'sm',
        radius: 'sm',
        color: 'base',
        fontSize: 'sm',
        maxWidth: '8xl',
        width: '100',
      }}
      onClickOutside={handleClickOutside}
      portalTarget={document.querySelector('#root') as HTMLElement}
      withPortal
      hasArrow={false}
    >
      <Box
        as="button"
        background="transparent"
        borderWidth="0"
        onClick={handleMobileToggleOrgSwitcher}
        fontWeight="medium"
        color="base"
        fontSize="sm"
        gap="xs"
        direction="row"
        cursor="pointer"
        alignItems="center"
        justifyContent="space-between"
        padding="md"
        radius="sm"
        hover={{
          background: 'secondary',
        }}
      >
        <Box>Switch organization</Box>
        <Icon name="caret-sm-right" size="sm" />
      </Box>
    </Popover>
  ) : (
    <Link to={`/${routes.getStarted}`} className="navlink">
      <Box padding="xs" hover={{ background: 'secondary' }} radius="xs" fontWeight="medium" direction="row" gap="xs">
        <Icon name="add" size="sm" />
        <span>Create an organization</span>
      </Box>
    </Link>
  );

  const orgPopoverContent = (
    <Box>
      <Box padding="md">{orgList}</Box>
      <Box padding="md" borderWidth="sm 0 0 0" borderColor="default">
        <Box fontSize="xs" padding="md" color="secondary">
          {user?.email}
        </Box>
        <Link to="/profile" state={{ backOrgId: organization?.id }} className="navlink">
          <Box padding="md" hover={{ background: 'secondary' }} radius="xs" fontWeight="medium">
            Profile
          </Box>
        </Link>
        <Box
          as="button"
          borderWidth="0"
          background="transparent"
          onClick={logout}
          padding="md"
          hover={{ background: 'secondary' }}
          cursor="pointer"
          color="base"
          radius="xs"
          fontWeight="medium"
        >
          Log out
        </Box>
      </Box>
    </Box>
  );

  if (isMobile) {
    return (
      <>
        <Modal ariaLabelledBy="titleBasic" isOpen={isModalOpen} onDismiss={closeModal}>
          <Modal.Body padding="0" overflow="auto" background="primary">
            <Box fontSize="md" fontWeight="bold" padding="2xl xl">
              Organizations
            </Box>
            {orgContent}
          </Modal.Body>
        </Modal>
        <Drawer isOpen={isMainNavOpen} onDismiss={handleMainNavClose} placement="left">
          <Box height="100" padding="5xl 0 lg lg" gap="md" background="primary" as="nav" fontSize="sm">
            <Box>
              <Popover
                content={orgPopoverContent}
                isOpen={isOpen}
                placement="bottom-start"
                offsetFromTarget={4}
                trapFocus
                contentContainerProps={{
                  padding: '0',
                  shadow: 'sm',
                  radius: 'sm',
                  color: 'base',
                  fontSize: 'sm',
                  maxWidth: isMobile ? '90' : '8xl',
                  width: '100',
                }}
                onClickOutside={handleClickOutside}
                hasArrow={false}
              >
                <Box
                  as="button"
                  alignSelf="flex-start"
                  background="transparent"
                  borderWidth="0"
                  onClick={handleToggle}
                  fontWeight="medium"
                  color="base"
                  fontSize="md"
                  gap="md"
                  direction="row"
                  cursor="pointer"
                  alignItems="center"
                  textAlign="left"
                  padding="md"
                  radius="sm"
                  hover={{
                    background: 'tertiary',
                  }}
                >
                  <OrganizationAvatar
                    name={organization?.name || 'Unknown Organization'}
                    logoUrl={organization?.logoUrl}
                  />
                  {organization?.name}
                  <Icon name="caret-sm-down" size="sm" />
                </Box>
              </Popover>
            </Box>
            <Box flex="auto" gap="md" overflow="auto" className="scroll-bar-thin" padding="0 lg 5xl 0">
              <Box gap="2xs">
                <NavLink end to={`/${organization?.id}`} className="navlink" onClick={handleMainNavClose}>
                  <MainNavItem iconName="dashboard">Dashboard</MainNavItem>
                </NavLink>
                <NavLink to={`/${organization?.id}/teams`} className="navlink" onClick={handleMainNavClose}>
                  <MainNavItem iconName="users">Teams</MainNavItem>
                </NavLink>
                <NavLink to={`/${organization?.id}/link`} className="navlink" onClick={handleMainNavClose}>
                  <MainNavItem iconName="logo-link">Link</MainNavItem>
                </NavLink>
                <NavLink to={`/${organization?.id}/env`} className="navlink" onClick={handleMainNavClose}>
                  <MainNavItem iconName="logo-env">ENV</MainNavItem>
                </NavLink>
                <MainNavItem iconName="logo-toggle" isDisabled={true}>
                  Toggle <ComingSoon />
                </MainNavItem>
                {canManageOrganizationIntegrations && (
                  <NavLink to={`/${organization?.id}/integrations`} className="navlink" onClick={handleMainNavClose}>
                    <MainNavItem iconName="stack">Integrations</MainNavItem>
                  </NavLink>
                )}
                <NavLink to={`/${organization?.id}/settings`} className="navlink" onClick={handleMainNavClose}>
                  <MainNavItem iconName="settings">Settings</MainNavItem>
                </NavLink>
                {canReadEvents && (
                  <NavLink to={`/${organization?.id}/events`} className="navlink">
                    <MainNavItem iconName="document">Events</MainNavItem>
                  </NavLink>
                )}
              </Box>
              {canManageOrganizationIntegrations && (
                <Box padding="0 lg">
                  <GetStarted organizationId={organization?.id} />
                </Box>
              )}
            </Box>
          </Box>
        </Drawer>
      </>
    );
  }

  return (
    <>
      <Box
        width="8xl"
        minWidth="8xl"
        height="100"
        padding={{ base: '5xl 0 lg lg', desktop: 'lg 0 lg lg' }}
        gap="md"
        position={isMobile ? 'absolute' : 'relative'}
        background={isMobile ? 'primary' : 'secondary'}
        shadow={isMobile ? 'lg' : undefined}
        id="mainNav"
        as="nav"
        fontSize="sm"
      >
        <Box>
          <Popover
            content={orgPopoverContent}
            isOpen={isOpen}
            placement="bottom-start"
            offsetFromTarget={4}
            trapFocus
            contentContainerProps={{
              padding: '0',
              shadow: 'sm',
              radius: 'sm',
              color: 'base',
              fontSize: 'sm',
              maxWidth: isMobile ? '90' : '8xl',
              width: '100',
            }}
            onClickOutside={handleClickOutside}
            hasArrow={false}
          >
            <Box
              as="button"
              alignSelf="flex-start"
              background="transparent"
              borderWidth="0"
              onClick={handleToggle}
              fontWeight="medium"
              color="base"
              fontSize="md"
              gap="md"
              direction="row"
              cursor="pointer"
              alignItems="center"
              textAlign="left"
              padding="md"
              radius="sm"
              hover={{
                background: 'tertiary',
              }}
            >
              <OrganizationAvatar
                name={organization?.name || 'Unknown Organization'}
                logoUrl={organization?.logoUrl}
              />
              {organization?.name}
              <Icon name="caret-sm-down" size="sm" />
            </Box>
          </Popover>
        </Box>
        <Box flex="auto" gap="4xl" overflow="auto" className="scroll-bar-thin" padding="0 lg 5xl 0">
          <Box gap="2xs">
            <NavLink end to={`/${organization?.id}`} className="navlink">
              <MainNavItem iconName="dashboard">Dashboard</MainNavItem>
            </NavLink>
            <NavLink to={`/${organization?.id}/teams`} className="navlink" onClick={handleMainNavClose}>
              <MainNavItem iconName="users">Teams</MainNavItem>
            </NavLink>
            <NavLink to={`/${organization?.id}/link`} className="navlink">
              <MainNavItem iconName="logo-link">Link</MainNavItem>
            </NavLink>
            <NavLink to={`/${organization?.id}/env`} className="navlink">
              <MainNavItem iconName="logo-env">ENV</MainNavItem>
            </NavLink>
            <MainNavItem iconName="logo-toggle" isDisabled={true}>
              Toggle <ComingSoon />
            </MainNavItem>
            {canManageOrganizationIntegrations && (
              <NavLink to={`/${organization?.id}/integrations`} className="navlink">
                <MainNavItem iconName="stack">Integrations</MainNavItem>
              </NavLink>
            )}
            <NavLink to={`/${organization?.id}/settings`} className="navlink">
              <MainNavItem iconName="settings">Settings</MainNavItem>
            </NavLink>
            {canReadEvents && (
              <NavLink to={`/${organization?.id}/events`} className="navlink">
                <MainNavItem iconName="document">Events</MainNavItem>
              </NavLink>
            )}
          </Box>

          {canManageOrganizationIntegrations && (
            <Box padding="0 lg">
              <GetStarted organizationId={organization?.id} />
            </Box>
          )}
        </Box>
        <Box padding="0 lg 0 0">
          {canInviteMembersToOrganization && (
            <NavLink to={`/${organization?.id}/settings/members?invite`} className="navlink">
              <MainNavItem iconName="user">Invite Members</MainNavItem>
            </NavLink>
          )}
        </Box>
      </Box>
    </>
  );
};

const MainContent = () => {
  const location = useLocation();
  const background = location.state && location.state.background;
  const { organization = {} as Organization } = useOrganization();
  const { isLoading, data } = useGetOrganizationBillingAccountQuery(organization.id);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (data?.paymentMethods.length === 0) {
        navigate(`/${routes.getStarted}/${data.id}`);
      }
    }
  }, [isLoading, data, navigate]);

  if (isLoading) return <Spinner size="xl" />;

  return (
    <MainCard padding="0">
      <Routes location={background || location}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/projects/create" element={<CreateProject />} />
        <Route path="/link" element={<ShortLinkDashboard />} />
        <Route path="/link/create" element={<CreateLink />} />
        <Route path="/link/:id" element={<ShortLinkDetail />} />
        <Route path="/env" element={<EnvDashboard />} />
        <Route path="/integrations" element={<IntegrationsOverview />} />
        <Route path="/integrations/setup/:integrationSlug" element={<IntegrationSetup />} />
        <Route path="/integrations/:id" element={<IntegrationDetails />} />
        <Route path="/settings/*" element={<Settings />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/teams/create" element={<CreateTeam />} />
        <Route path="/teams/:id" element={<TeamDetail />} />
        <Route path="/teams/:id/edit" element={<TeamSettings />} />
        <Route path="/events/*" element={<Events />} />
        <Route path="/app/create" element={<CreateApp />} />
        <Route path="/:projectId/*" element={<ProjectContainer />} />
        <Route path="/:projectId/app/:appId/*" element={<AppContainer />} />
      </Routes>
    </MainCard>
  );
};
