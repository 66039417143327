import { ListItem } from '../ListItem';
import { Box } from '@hyphen/hyphen-components';
import { App } from '../../services/apps';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import EnvironmentItem from '../common/EnvironmentItem';
import { useGetEnvsQuery } from '../../services/env';
import ClickToCopy from '../common/ClickToCopy';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { subject } from '@casl/ability';
import { EntityNames } from '../../types/executionContext';

export const AppItem = ({ app }: { app: App }) => {
  const { organization = {} as Organization } = useOrganization();
  const ability = useOrganizationAbilityContext();
  const canReadEnv = ability.can('read', subject(EntityNames.Env, { project: { id: app.project.id } }));

  if (canReadEnv) {
    return <AppWithSecrets app={app} orgId={organization.id} />;
  } else {
    return <AppWithEnvironments app={app} orgId={organization.id} />;
  }
};

const AppWithSecrets = ({ app, orgId }: { app: App; orgId: string }) => {
  const { data: envData } = useGetEnvsQuery({
    organizationId: orgId,
    appIds: [app.id],
  });

  const appWithSecrets = {
    ...app,
    environments: app.environments?.map((env) => ({
      ...env,
      secrets: envData?.data?.find((secret) => secret.projectEnvironment?.id === env.id),
    })),
  };

  const defaultSecrets =
    (envData?.data?.length ?? 0) > 0
      ? {
          name: 'Default (all)',
          secrets: envData?.data?.find((secret) => !secret.projectEnvironment),
        }
      : undefined;

  return (
    <ListItem
      title={{
        label: appWithSecrets.name,
        link: `/${orgId}/${appWithSecrets.project.alternateId}/app/${appWithSecrets.alternateId}`,
      }}
      subtitle={<ClickToCopy text={appWithSecrets.alternateId} fontSize="2xs" />}
    >
      <Box
        width="100"
        direction="row"
        gap="lg"
        overflow="auto"
        display="grid"
        fontSize="xs"
        style={{
          gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        }}
      >
        {defaultSecrets && <EnvironmentItem {...defaultSecrets} app={app} />}
        {appWithSecrets.environments?.map((appEnv) => (
          <EnvironmentItem key={appEnv.id} {...appEnv} app={app} />
        ))}
      </Box>
    </ListItem>
  );
};

const AppWithEnvironments = ({ app, orgId }: { app: App; orgId: string }) => {
  return (
    <ListItem
      title={{
        label: app.name,
        link: `/${orgId}/${app.project.alternateId}/${app.alternateId}`,
      }}
      subtitle={<ClickToCopy text={app.alternateId} fontSize="2xs" />}
    >
      <Box
        flex="auto"
        direction="row"
        gap="lg"
        overflow="auto"
        display="grid"
        fontSize="xs"
        style={{
          gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        }}
      >
        {app.environments?.map((appEnv) => (
          <EnvironmentItem key={appEnv.id} {...appEnv} app={app} hideSecrets />
        ))}
      </Box>
    </ListItem>
  );
};
